/* src/App.css */
@font-face {
  font-family: 'Contra';
  src: url('./fonts/Contra.ttf') format('truetype');
}

.App {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100vw;
  font-family: 'Contra', 'Press Start 2P', cursive;
}

.contra-loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  color: white;
  text-shadow: 2px 2px 0 #000;
  background-color: black;
  position: absolute;
  right: -100%;
  animation: slide-in 4s linear forwards;
}

.contra-loading-screen.animate {
  right: 0%;
  transform: translateX(50%);
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.power-on-button {
  font-family: 'Contra', 'Press Start 2P', cursive;
  font-size: 16px;
  padding: 5px 10px;
  color: red;
  background-color: gray;
  border: 2px solid rgb(110, 110, 110);
  border-radius: 5px;
  cursor: pointer;
  width: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.power-on-button:hover {
  background-color: #333;
}

.contra-loading-screen h1 {
  font-size: 24px;
  margin-bottom: 10px;
  width: 80%;
  text-align: center;
}

.contra-loading-screen p {
  font-size: 16px;
  margin-bottom: 20px;
  width: 80%;
  text-align: center;
}

.player-select {
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 80%;
}

.player-select-secret {
  display: flex;
  justify-content: center;
  padding-top:15px;
  gap: 10px;
  width: 100%;
}

.player {
  font-size: 12px;
  padding: 5px 10px;
  border: 2px solid white;
  border-radius: 5px;
  width: 100px;
  text-align: center;
}

.player a:link, .player a:visited, .player a:hover, .player a:active{
  color:white;
  text-decoration: none;
}

.flicker{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  animation: flicker 0.5s linear;
}

@keyframes flicker{
  0%, 100%{
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .contra-loading-screen{
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
  .contra-loading-screen h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }

  .contra-loading-screen p {
    font-size: 24px;
    margin-bottom: 40px;
  }

  .player-select {
    gap: 20px;
    width: 300px;
  }

  .player {
    font-size: 16px;
    padding: 10px 20px;
    width: 150px;
  }

  .power-on-button{
    font-size:24px;
    padding: 10px 20px;
    width: 100px;
  }
}